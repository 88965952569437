var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ml-3 mr-3"
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://play.google.com/store/apps/details?id=com.eparisheva.client&hl=en_IN&gl=US"
    }
  }, [_c('b-img', {
    staticClass: "w-100",
    attrs: {
      "src": "img/bottomBanner.png"
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }